import { Box, Container, Hidden } from '@material-ui/core';
import _ from 'lodash';
import { FC, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { worksiteStyles } from '../../assets/styles/Dashboard.css';
import { SubHeader } from '../../components';
import {
  AlertWorkers,
  BatteryReplacers,
  GroupStatus,
  OperationUnits,
  Wbgt,
} from '../../components/dashboard/worksite';
import { fetch, useAlert, useAuth } from '../../functions';
import { CommonState } from '../../redux/store';
import { isCheckBeforeTargetDate } from '../../utils/DateUtil';
import { useLocation } from 'react-router-dom';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorksiteDashboard: FC = () => {
  const wsClasses = worksiteStyles();
  const { id } = useParams<Params>();
  const [data, setData] = useState<any>(null);
  const [worksite, setWorksite] = useState<any>();
  const [organization, setOrganization] = useState<any>();
  const organizations = useSelector((state: CommonState) => state.common);
  const [displayDateTime, setDisplayDateTime] = useState<string>('');
  const { alertWithCode } = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();
  const { beforeDateTime } =
    (location.state as { beforeDateTime: string }) || {};

  const { isAllowOrganization, isAllowOrganizationWithAuth } = useAuth();
  const isAllow =
    typeof organization !== 'undefined'
      ? isAllowOrganization(organization.id)
      : false;

  const subMenus = [
    {
      icon: '',
      name: 'レポート',
      link: `/workSite/${id}/report/worker/daily`,
    },
    {
      icon: '',
      name: 'お知らせ管理',
      link: `/workSite/${id}/notifications`,
      allow:
        typeof organization !== 'undefined'
          ? isAllowOrganizationWithAuth(organization.id, [
              'SYSTEM_ADMIN',
              'WORK_SITE_ADMIN',
            ])
          : false,
    },
    {
      icon: '',
      name: 'アラート設定管理',
      link: `/workSite/${id}/alert`,
      allow: isAllow,
    },
    {
      icon: '',
      name: 'アラート通知先設定',
      link: `/workSite/${id}/edit#alertEmail`,
      allow: isAllow,
    },
    {
      icon: '',
      name: '装着者編集',
      link: `/workSite/${id}/workers`,
      allow: isAllow,
    },
    {
      icon: '',
      name: 'センサーデバイス一覧',
      link: `/workSite/${id}/devices`,
      allow: isAllow,
    },
    {
      icon: '',
      name: '現場編集',
      link: `/workSite/${id}/edit`,
      allow: isAllow,
    },
  ];

  //  表示データの取得
  useEffect(() => {
    (async () => {
      //  現場の取得
      const resultWorksite = await fetch.get(`/work_site/${id}`);
      if (resultWorksite.status === 200) {
        setWorksite(resultWorksite.data);

        //  組織の取得
        const resultOrganization = await fetch.get(
          `/organization/${resultWorksite.data.organizationId}`,
        );
        if (resultOrganization.status === 200) {
          setOrganization(resultOrganization.data);
        } else if (resultOrganization.status === 500) {
          alertWithCode(resultOrganization.status);
        }
      } else if (resultWorksite.status === 500) {
        alertWithCode(resultWorksite.status);
      }
    })();
  }, [id, organizations, alertWithCode]);

  //　組織ダッシュボードで選択した日時をセット
  useEffect(() => {
    beforeDateTime && setDisplayDateTime(beforeDateTime);
  }, [beforeDateTime]);

  //  ダッシュボードデータの取得
  useEffect(() => {
    (async () => {
      const result =
        displayDateTime !== ''
          ? await fetch.get(`/work_site/${id}/dashboard`, {
              displayDateTime: `${displayDateTime}`,
            })
          : await fetch.get(`/work_site/${id}/dashboard`);

      if (result.status === 200) {
        setData(result.data);
      } else {
        alertWithCode(result.status);
        dispatch(push('/'));
      }
    })();
  }, [id, displayDateTime, alertWithCode, dispatch]);

  const isBeforeTargetDate = useMemo(() => {
    return isCheckBeforeTargetDate(displayDateTime);
  }, [displayDateTime]);

  return (
    <>
      <SubHeader
        title={organization && worksite?.name}
        subTitle={organization?.name}
        link={`/dashboard/organization/${organization?.id}`}
        menus={subMenus}
      />

      {data && worksite && organization && (
        <Container maxWidth="xl">
          <Box className={wsClasses.root} display="flex">
            <Box className={wsClasses.main} flexGrow={1}>
              <AlertWorkers
                data={_.sortBy(data.alertWorkers, ['alertStartTime'])}
              />

              <Hidden mdUp>
                <Box display="flex">
                  <Wbgt value={data.log.wbgtAverage} />
                  <OperationUnits
                    value={data.log.activeDeviceCount}
                    total={data.deviceCount}
                  />
                </Box>
              </Hidden>

              <GroupStatus
                data={data.workSiteGroupLogs}
                worksite={worksite}
                organization={organization}
                isBeforeTargetDate={isBeforeTargetDate}
                displayDateTime={
                  displayDateTime === '' ? data.log.timestamp : displayDateTime
                }
                setDisplayDateTime={setDisplayDateTime}
              />
            </Box>

            <Box className={wsClasses.sub}>
              <Hidden smDown>
                <Wbgt value={data.log.wbgtAverage} />
                <OperationUnits
                  value={data.log.activeDeviceCount}
                  total={data.deviceCount}
                />
              </Hidden>
              <BatteryReplacers data={data.batteryAlertModels} />
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default WorksiteDashboard;
