import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import useStyles from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Props = {
  sub?: boolean;
};

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpSummaryNotice = (props: Props) => {
  const { sub = false } = props;
  const classes = useStyles();
  const location = useLocation();

  useEffect(()=>{
    const element = document.getElementById(location.hash.replace('#', ''));
    if(element !== null) {
      element.scrollIntoView(true);
    }
  }, [location.hash]);

  return (
    <Paper className={classes.summaryBody}>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/notice#notice-1">お知らせを見る</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>ログイン前に見る</li>
            <li>ログイン後に見る</li>
          </ul>
        )}
      </div>
      <div className={classes.summaryBodyListItem}>
        <Link to="/help/usage/notice#notice-2">管理ユーザーへお知らせを投稿する</Link>
        {sub && (
          <ul className={classes.summaryBodyListItemSub}>
            <li>全ての管理ユーザーにお知らせを投稿する</li>
            <li>アプリユーザーにお知らせを投稿する</li>
          </ul>
        )}
      </div>
    </Paper>
  );
};

export default HelpSummaryNotice;
