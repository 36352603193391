import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  SubHeader,
  WorkerCsvSampleDownloadButton,
  WorkerCommit,
  WorkerUploadConfirm,
  WorkerUploadForm,
} from '../../components';
import { fetch, csvParser, useAlert } from '../../functions';
import { CsvWorkerHeader } from '../../constants/csv';
import withFormStatus, { FormStatusProps } from '../../hoc/withFormStatus';
import useStyles from '../../assets/styles/Master.css';

/**
 * 正規表現用のラベルリスト
 *
 *
 * @method Constants
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const LABEL_LIST =
  '新人|高齢|疾患|発症歴者|喫煙者|習慣飲酒|肥満|特殊作業|要注意';

/**
 *
 *
 *
 * @method Interface
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
type Params = {
  id: string;
};

/**
 *
 *
 *
 * @method Validation
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
export const contentSchema = yup.object({
  file: yup.mixed().required('ファイルをアップロードしてください'),
});
export const workerSchema = yup.object({
  ログイン名: yup
    .string()
    .matches(/^[ァ-ヴー０-９]*$/, {
      message: 'ログイン名は全角カタカナと全角数字のみ使用できます',
    })
    .matches(/^(?!９９９９$).*$/, {
      message: 'ログイン名に９９９９は使用できません',
    })
    .required('ログイン名は必須です'),
  グループ番号: yup
    .string()
    .matches(/^([1-9]?|10)$/, {
      message: 'グループ番号は1~10で入力する必要があります',
    })
    .required('グループ番号は必須です'),
  ユーザー名: yup
    .string()
    .specialCharacter('ユーザー名に機種依存文字が含まれています')
    .required('ユーザー名を入力してください'),
  'ユーザー名（サブ）': yup
    .string()
    .specialCharacter('ユーザー名（サブ）に機種依存文字が含まれています'),
  'ユーザー名　フリガナ': yup
    .string()
    .matches(/^[ァ-ヴー]*$/, {
      message: 'ユーザー名　フリガナは全角カタカナのみ使用できます',
      excludeEmptyString: true,
    })
    .required('ユーザー名　フリガナを入力してください'),
  'ユーザー名　フリガナ（サブ）': yup.string().matches(/^[ァ-ヴー]*$/, {
    message: 'ユーザー名　フリガナ（サブ）は全角カタカナのみ使用できます',
    excludeEmptyString: true,
  }),
  生年月日: yup
    .string()
    .matches(
      /^[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}$/,
      '生年月日はYYYY/MM/DDで入力してください',
    )
    .required('生年月日を入力してください'),
  性別: yup
    .string()
    .matches(
      /^(male|female|other)$/,
      '性別は male、female、other のみ入力できます',
    )
    .required('性別を入力してください'),
  メールアドレス: yup
    .string()
    .email('メールアドレスが不正です')
    .specialCharacter('メールアドレスに機種依存文字が含まれています'),
  電話番号: yup
    .string()
    .specialCharacter('電話番号に機種依存文字が含まれています'),
  郵便番号: yup
    .string()
    .matches(
      /^[0-9]{7}$/,
      '郵便番号は数字7桁（ハイフンはなし）で入力してください',
    )
    .required('郵便番号を入力してください'),
  管理用住所: yup
    .string()
    .specialCharacter('管理用住所に機種依存文字が含まれています'),
  所属: yup
    .string()
    .specialCharacter('所属に機種依存文字が含まれています')
    .required('所属を入力してください'),
  管理番号: yup
    .string()
    .max(255, '管理番号は255文字まで入力できます')
    .specialCharacter('管理番号に機種依存文字が含まれています'),
  アラート設定: yup.string().matches(/^[0-3]?$/, {
    message: 'アラート設定は0~3で入力する必要があります',
  }),
  アラート設定値: yup.string().when('アラート設定', {
    is: (value: any) => value === '0',
    then: yup
      .string()
      .matches(/^[0-9]{3,}$/, {
        message: 'アラート設定値は100以上を指定してください',
      })
      .required('アラート設定値を入力してください'),
  }),
  ラベル: yup
    .string()
    .matches(new RegExp(`^((${LABEL_LIST})\\s)*((${LABEL_LIST}))?$`), {
      message: 'ラベルに登録できないラベルが入力されています',
    }),
});

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const WorkersCreate: FC<FormStatusProps> = (props) => {
  const { mode, changeMode } = props;
  const classes = useStyles();
  const { alertWithCode } = useAlert();
  const { id } = useParams<Params>();
  const [data, setData] = useState<any[]>([]);
  const [workSite, setWorkSite] = useState<any>();

  //  メニューの追加
  const subMenus = [
    {
      icon: <span />,
      name: 'CSVサンプルダウンロード',
      link: '',
      component: <WorkerCsvSampleDownloadButton />,
    },
  ];

  //  フォームの定義
  const formik = useFormik({
    initialValues: {} as { file: File },
    validationSchema: contentSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, actions) => {
      if (mode === 'editing') {
        // CSV をパースする
        const result = await csvParser({
          file: values.file,
          csvHeader: CsvWorkerHeader,
          delimiter: ',',
          validSchema: workerSchema,
        });

        //  エラーを処理する
        if (result.error.length > 0) {
          result.error.forEach((e) => {
            actions.setFieldError(e.key, e.message);
          });
        } else {
          setData(result.data);
          changeMode('confirm');
        }
      } else {
        //  ファイルを送信する
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('workSiteId', id);

        const result = await fetch.post(`/worker/bulk_regist`, formData);
        if (result.status === 200) {
          changeMode('commit');
        } else if (result.status === 500) {
          alertWithCode(result.status);
        } else {
          alert('エラーが発生しました');
        }
      }
    },
  });

  useEffect(() => {
    (async () => {
      //  現場情報の取得
      const result = await fetch.get(`/work_site/${id}`);
      if (result.status === 200) {
        setWorkSite(result.data);
      } else if (result.status === 500) {
        alertWithCode(result.status);
      }
    })();
  }, [id, alertWithCode]);

  return (
    <>
      <SubHeader
        title={workSite?.name}
        subTitle={`装着者一括更新${
          { editing: '', confirm: '(確認)', commit: '(完了)' }[mode]
        }`}
        link={`/dashboard/workSite/${id}`}
        menus={subMenus}
      />

      <div className={classes.root}>
        {mode === 'editing' && <WorkerUploadForm {...formik} />}
        {mode === 'confirm' && <WorkerUploadConfirm {...formik} data={data} />}
        {mode === 'commit' && (
          <WorkerCommit
            link={`/workSite/${id}/workers`}
            message={`装着者を一括登録しました。\n装着者一覧ページから確認してください。`}
          />
        )}
      </div>
    </>
  );
};

export default withFormStatus(WorkersCreate);
