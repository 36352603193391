import { Paper } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import HelpSection from './parts/HelpSection';
import HelpTitle from './parts/HelpTitle';
import HelpSubtitle from './parts/HelpSubtitle';
import HelpParagraph from './parts/HelpParagraph';
import HelpImage from './parts/HelpImage';
import HelpImageFlex from './parts/HelpImageFlex';
import UsersIcon from './parts/UsersIcon';
import WarningIcon from './parts/WarningIcon';
import PointIcon from './parts/PointIcon';
import PencilIcon from './parts/PencilIcon';
import TrashIcon from './parts/TrashIcon';
import useStyles, { useContentsStyles } from '../../../assets/styles/Help.css';

/**
 *
 *
 *
 * @method Components
 * @version 1.0.0
 * -------------------------------------------------------------------------- */
const HelpContentsEditing = () => {
  const classes = useStyles();
  const contentClasses = useContentsStyles();

  return (
    <Paper className={classes.contentsPaper}>
      <HelpSection id="editing-1" first={true}>
        <div style={{ backgroundColor: 'white' }}>
          <HelpTitle>自分のアカウント情報</HelpTitle>
          <HelpSubtitle>アカウント情報変更</HelpSubtitle>
          <HelpParagraph icon={'①'}>
            <div>
              アカウント情報を変えるには右上メニューバーのアカウント設定から変更します。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>
              メニューバー右端の <UsersIcon />{' '}
              を選択しメニューを表示させ、「アカウント設定」を選択します。
            </div>
          </HelpParagraph>
          <HelpImage title="アカウント設定" src="/images/help/edit-1.png" />
          <HelpParagraph icon={'③'}>
            <div>「アカウント情報編集」を選択します。</div>
          </HelpParagraph>
          <HelpImage
            title="③アカウント情報表示画面"
            src="/images/help/edit-2.png"
          />
          <HelpParagraph icon={'④'}>
            <div>
              変更したい内容にユーザー名や所属情報を編集し「確認」を選択します。
            </div>
          </HelpParagraph>
          <HelpImage
            title="④アカウント情報編集画面"
            src="/images/help/edit-3.png"
          />
          <HelpParagraph icon={<WarningIcon />}>
            <div>メールアドレスは変更できません。</div>
            <div>
              ※本システムは法人向けのため、プライベートな個人アドレスへの変更を防止し、情報漏洩リスクへの対策措置としています。ご了承ください。
            </div>
            <div>
              新しいメールアドレスをご利用になりたい場合は、新たに管理者追加のフローにて招待していただき、旧メールアドレスのユーザーを各ダッシュボードの管理者一覧から削除してください。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'⑤'}>
            <div>
              確認画面にて内容に問題がなければ「確定」ボタンを選択すると変更が完了します。
            </div>
          </HelpParagraph>
          <HelpSubtitle>閲覧権限の変更</HelpSubtitle>
          <HelpParagraph>
            <div>各組織ダッシュボードごとに閲覧権限の設定が可能です。</div>
            <div>
              ご自身の閲覧可能な組織ダッシュボードの親子関係と、各組織での現在の閲覧権限についてご確認の上、権限の変更設定を行ってください。
            </div>
          </HelpParagraph>
          <div className={contentClasses.image}>
            <div>権限設定早見表</div>
            <img
              className={contentClasses.imageImgL}
              src="/images/help/edit-4.png"
              alt="権限設定早見表"
            />
          </div>
          <HelpParagraph icon={'①'}>
            <div>
              変更したい組織ダッシュボードの右上メニューから「管理者一覧」を選択してください。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'②'}>
            <div>
              一覧の中から変更したい管理者ユーザー行の左端 <PencilIcon />{' '}
              アイコンを選択してください。
            </div>
          </HelpParagraph>
          <HelpParagraph icon={'③'}>
            <div>変更したい権限を選択してください。</div>
          </HelpParagraph>
          <HelpImageFlex
            title="③権限変更画面"
            src="/images/help/edit-5.png"
            src2="/images/help/edit-6.png"
          />
          <HelpParagraph icon={'④'}>
            <div>
              「確認」を選択し確認画面にて、内容に問題がなければ「確定」ボタンを選択すると変更が完了します。
            </div>
          </HelpParagraph>
        </div>
      </HelpSection>
      <HelpSection id="editing-2">
        <HelpTitle>TOPページの表示ダッシュボード</HelpTitle>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            TOPページまたは左上メニューをクリックすると、閲覧権限があるダッシュボードを一覧で見ることができます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            現場のダッシュボードは、現場が紐づく組織に権限があれば自動で閲覧できるようになります。
          </div>
        </HelpParagraph>
        <HelpImageFlex
          title="TOPページ"
          src="/images/help/edit-7.png"
          src2="/images/help/edit-8.png"
        />
        <HelpSubtitle>閲覧可能なダッシュボードを増やす</HelpSubtitle>
        <HelpParagraph>
          <div>
            追加したい組織ダッシュボードの管理者、または親組織のadmin権限者に管理者として追加してもらいます。
          </div>
        </HelpParagraph>
        <HelpParagraph>
          <div>
            追加してもらう場合は、
            <Link to="/help/usage/basic#Step7">コチラ</Link>{' '}
            を参照してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            権限を追加したい組織の親組織でadmin権限を保有していれば、配下の組織ダッシュボードに自分で権限を追加することが可能です。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>
            追加したい組織の親組織ダッシュボードの「配下の会社・部署」から追加したい子組織の名前をクリックします。
          </div>
        </HelpParagraph>
        <HelpImage
          title="親組織ダッシュボードの配下の会社・部署"
          src="/images/help/edit-9.png"
        />
        <HelpParagraph icon={'②'}>
          <div>
            子組織のダッシュボードが表示されるので、右上メニューバーの「管理者一覧」を選択してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            「＋管理者追加」を選択し、ご自身のメールアドレスを入力し権限を設定してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            入力が完了したら「確認」を選択し、内容を確認後「確定」を選択するとTOP画面に表示される組織ダッシュボードと追加した組織が管理する現場ダッシュボードが追加されています。
          </div>
        </HelpParagraph>
      </HelpSection>

      <HelpSection id="editing-3">
        <HelpTitle>組織情報</HelpTitle>
        <HelpParagraph icon={<PointIcon />}>
          <div>組織情報の中で変更できるのは2か所です。</div>
          <div>A：管理範囲名</div>
          <div>B：組織名</div>
        </HelpParagraph>
        <HelpImage title="変更できる組織情報" src="/images/help/edit-10.png" />
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            Aを変更すると同じ名前を引き継いで作成した組織のすべてに変更が反映されます。
          </div>
          <div>
            下図のAの場合水色の範囲の組織全てに反映され、ピンクの範囲は変更されません。
          </div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/edit-11.png" />
        <HelpParagraph icon={'①'}>
          <div>変更したい組織の親組織のダッシュボードを開きます。</div>
          <div>
            例：△△チームを変更したい場合○○製造部のダッシュボードを開く。
          </div>
          <div className={contentClasses.textWithIcon}>
            <PointIcon />
            &nbsp;○○製造部でadmin権限である必要があります。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>
            親組織のダッシュボード右上の「会社・部署一覧」を選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            編集したい組織行の左端 <PencilIcon /> アイコンを選択します。
          </div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/edit-12.png" />
        <HelpParagraph icon={'④'}>
          <div>
            AまたはB欄を変更したい内容に修正し「確認」を選択し、内容を確認後「確定」を選択すると組織情報の変更が完了します。
          </div>
          <div className={contentClasses.image}>
            <img
              className={contentClasses.imageImgM}
              src="/images/help/edit-13.png"
              alt=""
            />
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="editing-4">
        <HelpTitle>現場情報</HelpTitle>
        <HelpParagraph icon={<PointIcon />}>
          <div>変更できる現場情報は以下の項目です。</div>
          <div>・現場名</div>
          <div>・郵便番号</div>
          <div>・住所</div>
          <div>・グループ1～10設定</div>
          <div>　・グループ名</div>
          <div>　・アラート時の通知先メールアドレス</div>
          <div>　・アラート設定値</div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>
            変更したい現場が紐づいている組織のダッシュボードを開きます。
          </div>
          <div>
            例：AA現場を変更したい場合は、AA現場が紐づく○○製造部のダッシュボードを開きます
          </div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/edit-14.png" />
        <HelpParagraph icon={'②'}>
          <div>親組織のダッシュボード右上の「現場一覧」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            編集したい現場行の左端 <PencilIcon /> アイコンを選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>変更したい項目を編集します。</div>
        </HelpParagraph>
        <HelpImage title="" src="/images/help/edit-15.png" />
        <HelpParagraph icon={'⑤'}>
          <div>
            変更したい内容に修正し「確認」を選択し、内容を確認後「確定」を選択すると現場情報の変更が完了します。
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="editing-5">
        <HelpTitle>センサーデバイス情報</HelpTitle>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            センサーデバイス情報は変更ではなく、削除・追加の操作で登録情報を書き換えます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>
            変更したいセンサーデバイスが登録されている現場のダッシュボードを開きます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>
            現場のダッシュボード右上の「センサーデバイス一覧」を選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            変更したいセンサーデバイス行の左端 <TrashIcon />{' '}
            アイコンを選択し、表示されるポップアップの「削除」を選択し削除します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>変更したいセンサーデバイス番号を登録します。</div>
          <div>
            追加方法は<Link to="/help/usage/basic#Step4">コチラ</Link>
            をご参照ください。
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="editing-6">
        <HelpTitle>装着者情報</HelpTitle>
        <HelpParagraph color={true} icon={<WarningIcon />}>
          <div>ログイン名で個人を区別しています。</div>
          <div>
            装着者情報を削除すると、今後その現場では同じログイン名を使用できなくなります。
          </div>
          <div>
            ログイン名は使いまわさず、個人ごとに固有のログイン名を設定してください。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'①'}>
          <div>
            変更したい装着者が登録されている現場のダッシュボードを開きます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>現場のダッシュボード右上の「装着者一覧」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            変更したい装着者行の左端 <PencilIcon /> アイコンを選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            変更したい内容に修正し「確認」を選択し、内容を確認後「確定」を選択すると装着者情報の変更が完了します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            大人数をまとめて変更したい場合は、「装着者一覧ダウンロード」から登録中のデータをcsvでダウンロードし、修正したい内容で上書きし、
            <Link to="/help/usage/basic#Step5">一括登録操作</Link>
            で変更することも可能です。
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="editing-7">
        <HelpTitle>アラート設定値</HelpTitle>
        <HelpParagraph icon={<PointIcon />}>
          <div>
            季節や天気、装着者の暑熱順化状態に合わせて、一人ひとりに最適な設定値での運用を行うことを推奨します。
          </div>
        </HelpParagraph>
        <HelpParagraph>
          <div>
            アラート設定値の変更方法詳細は
            <Link to="/help/usage/basic#Step6">コチラ。</Link>
          </div>
        </HelpParagraph>
      </HelpSection>
      <HelpSection id="editing-8">
        <HelpTitle>管理者情報</HelpTitle>
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            他の管理者が変更可能な情報は権限設定のみであり、組織ダッシュボードごとの操作が必要です。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            メールアドレスやユーザー名は管理者本人のみ変更が可能です。これらや管理者本人で権限設定を変更する場合は
            <Link to="/help/usage/editing#editing-1">コチラ。</Link>
          </div>
        </HelpParagraph>
        <HelpSubtitle>管理者の権限を変更</HelpSubtitle>
        <HelpParagraph icon={'①'}>
          <div>
            変更したい管理者が登録されている組織のダッシュボードを開きます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>組織のダッシュボード右上の「管理者一覧」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            変更したい管理者行の左端 <PencilIcon /> アイコンを選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            変更したい権限に修正し「確認」を選択し、内容を確認後「確定」を選択すると管理者権限の変更が完了します。
          </div>
        </HelpParagraph>
        <HelpSubtitle>管理者を削除</HelpSubtitle>
        <HelpParagraph icon={'①'}>
          <div>
            閲覧権限を削除したい管理者が登録されている組織のダッシュボードを開きます。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'②'}>
          <div>組織のダッシュボード右上の「管理者一覧」を選択します。</div>
        </HelpParagraph>
        <HelpParagraph icon={'③'}>
          <div>
            変更したい管理者行の左端 <TrashIcon /> アイコンを選択します。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={'④'}>
          <div>
            一度削除すると再登録が必要になるエラーメッセージと、削除対象者のお名前に間違いが無いかをご確認いただき、問題なければ「削除」を選択すると、作業を行った組織とその組織に紐づく現場の閲覧権限が無くなります。
          </div>
        </HelpParagraph>
        <HelpParagraph icon={<WarningIcon />}>
          <div>
            1つの組織ダッシュボードで権限を削除しても、他の組織ダッシュボードで閲覧権限があれば他の組織の閲覧権限は保持されたままとなります。
          </div>
          <div>管理者が退職されるなど、権限を削除する際はご注意ください。</div>
        </HelpParagraph>
      </HelpSection>
    </Paper>
  );
};

export default HelpContentsEditing;
